﻿(function ($) {
  $(document).ready(function () {
    if (window.location.hash) {
      var title = window.location.hash.replace("#", "");
      $('[data-toggle="tab"]').each(function () {
        if ($(this).text().toLowerCase() == title.toLowerCase()) {
          $(this).tab("show");
          $('html, body').animate({
            scrollTop: $(this).offset().top
          }, 2000);
        }
      });
    }
  });
})(jQuery);
